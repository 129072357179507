<div *ngIf="file" style="height:94%;width:100%;  align-items: center;">
    <div class="pdf-toolbar ">
        <!-- Add buttons for navigation and indicators for current page/view -->
        <button (click)=" onClose()">Cancel</button>
        <!-- <span>Page {{ currentPage }} of {{ totalPages }}</span> -->
        <!-- <button (click)="onNextPage()" [disabled]="currentPage >= totalPages">Next</button> -->
        <!-- Add other controls as needed -->
    </div>


    <ngx-doc-viewer [url]="file.file" viewer="url" disableContent="popout" viewer="google" style="height:100%; ">
    </ngx-doc-viewer>


    <!-- Placeholder for other file types -->
    <ng-container *ngIf="!isPDF()">
        <p>Preview not available for this file type.</p>
    </ng-container>
</div>