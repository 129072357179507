<search-bar [withSearch]="false"></search-bar>


<ng-container *ngIf="loaded$ | async; else content">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>



<ng-template #content>

    <!-- <div class="row">
        <div class="col-xs-12">
            <h5>
                <a routerLink="../../">{{routeParams.name}} - Albums </a> / Tracks
            </h5>
        </div>
    </div> -->

    <h4 *ngIf="reciterName.length">{{reciterName}} - Khatma</h4>
    <mat-divider *ngIf="reciterName.length"></mat-divider>

    <div class="content-portfolio responsive-grid-portfolio">
        <recitation-card *ngFor="let item of recitations" [recitation]="item"></recitation-card>
    </div>

    <div class="" *ngIf="!recitations.length">

        <mat-card-title> No items existed yet try adding one now</mat-card-title>

    </div>

</ng-template>