<search-bar></search-bar>


<ng-container *ngIf="loading$ | async; else content">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>


<ng-template #content>
    <div class="content-portfolio responsive-grid-portfolio">
        <mat-card *ngFor="let item of values$ | async" (click)="onCardPressed(item)">

            <mat-card-content>
                <img [src]="item.reciter?.image" class="item-image">
            </mat-card-content>

            <mat-card-header class="album-header">

                <mat-card-title class="item-title">{{item.name.en}}</mat-card-title>

                <mat-card-subtitle class="item-subtitle">By Sheikh: {{item.reciter?.name?.en}}</mat-card-subtitle>

                <mat-card-subtitle class="item-subtitle">
                    {{item.totalPlays > 0 ? item.totalPlays + ' Times played' : ''}}
                </mat-card-subtitle>

            </mat-card-header>

        </mat-card>
    </div>

    <div class="" *ngIf="(loading$ | async)">
        <mat-card-title> No items existed yet try adding one now</mat-card-title>
    </div>

</ng-template>