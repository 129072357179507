<!-- <div>
    <search-bar [withSearch]="false"></search-bar>
    <form [formGroup]="fileForm" (ngSubmit)="submit()">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="fileForm.get('name')?.errors?.['required'] ">Name is required.</mat-error>
            <mat-error *ngIf="fileForm.get('name')?.errors?.['minlength']">Name must be at least 10
                characters.</mat-error>
            <mat-error *ngIf="fileForm.get('name')?.errors?.['maxlength']">Name must be less than 80
                characters.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
                <mat-option *ngFor="let language of languages$ | async" [value]="language.id">{{ language.name
                    }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Categories</mat-label>
            <mat-select formControlName="categories" multiple>
                <mat-option *ngFor="let category of categories$ | async" [value]="category.id">{{
                    category.name.currentValue
                    }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div>
            <input type="file" (change)="onFileSelect($event)" [multiple]="false">
        </div>

        <button mat-raised-button color="primary" type="submit" [disabled]="!fileForm.valid">Submit</button>
    </form>
</div> -->

<mat-card>

    <mat-progress-bar *ngIf="!(loaded$ | async)" mode="indeterminate"></mat-progress-bar>

    <div class="title">
        <mat-card-title>
            <span>{{ pageTitle}}</span>
        </mat-card-title>
        <div class="actions">
            <button routerLink="../library" mat-flat-button color="primary">
                <mat-icon>keyboard_arrow_left</mat-icon>
                Back to list
            </button>
        </div>
    </div>

    <mat-card-content>
        <form [formGroup]="form" *ngIf="form">
            <div class="field-container">
                <div class="stretch-row">
                    <upload-library-file [fileUrl]="fileCtrl.value" (newFile)="setFile($event)">
                    </upload-library-file>
                </div>
                <div class="stretch-row">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" minlength="10" maxlength="80" />
                        <mat-error *ngIf="nameCtrl?.errors?.['required']">This field is required</mat-error>
                        <mat-error *ngIf="nameCtrl?.errors?.['minlength']">Minimum length of 10 characters is
                            required</mat-error>
                        <mat-error *ngIf="nameCtrl?.errors?.['maxLength']">Maximum length of 80 characters
                            exceeded</mat-error>
                    </mat-form-field>
                </div>

                <div class="stretch-row">
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea formControlName="description" matInput rows="7"></textarea>
                        <mat-hint class="optional">Optional</mat-hint>
                    </mat-form-field>
                </div>

                <div class="two-columns">
                    <mat-form-field appearance="outline">
                        <mat-label>Language</mat-label>
                        <mat-select formControlName="language">
                            <mat-option *ngFor="let language of languages$ | async" [value]="language.id">
                                {{ language.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="languageCtrl?.errors?.['required']">This field is required</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline">
                        <mat-label>Categories</mat-label>
                        <mat-select multiple formControlName="categories">
                            <mat-option *ngFor="let cat of categories$ | async" [value]="cat.id">
                                {{ cat.name.en }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="categoriesCtrl?.errors?.['required']">This field is required</mat-error>
                    </mat-form-field>
                </div>
                <!-- <div class="stretch-row"> -->
                <!-- <mat-form-field appearance="outline">
                        <mat-label>Auto join for new user</mat-label>
                        <mat-select [value]="true" formControlName="autoJoined">
                            <mat-option [value]="true">Yes</mat-option>
                            <mat-option [value]="false">No</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                <!-- <mat-form-field appearance="outline">
                        <mat-label>Club Fees</mat-label>
                        <input matInput formControlName="clubFees" type="number" min="0" />
                        <mat-error *ngIf="clubFeesCtrl?.errors?.['required']">This field is required</mat-error>
                        <mat-error *ngIf="clubFeesCtrl?.errors?.['min']">should be at least 0</mat-error>
                    </mat-form-field> -->
                <!-- </div> -->
            </div>

        </form>


    </mat-card-content>

    <mat-card-actions align="end">
        <button mat-flat-button type="submit" (click)="submit()" [disabled]="isDisabled$ | async" class="action"
            color="primary">
            Save
        </button>
    </mat-card-actions>
</mat-card>