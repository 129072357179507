<!-- <mat-card (click)="onItemPressed(item)">


    <img mat-card-image [src]="item.image" />

    <mat-card-content class="card-content">


        <mat-card-title>{{ item.title.en ?? '' }} </mat-card-title>
        <mat-card-subtitle class="item-subtitle">
            {{item.totalPlays > 0 ? item.totalPlays + ' Times played' : ''}}
        </mat-card-subtitle>
        <mat-card-subtitle class="item-subtitle">
            {{item.totalDownloads > 0 ? item.totalDownloads + ' Times downloaded' : ''}}
        </mat-card-subtitle>

        <button class="btn btn-primary" (click)="onPlayOrPause()">
            Play
        </button>
        <p> {{isBeingPlayed()? 'Played':'Paused'}}</p>
    </mat-card-content>

</mat-card> -->

<mat-card>
    <img mat-card-image [src]="item.image" />

    <mat-card-content class="card-content">
        <mat-card-title>{{ item.title.en ?? '' }}</mat-card-title>
        <mat-card-subtitle class="item-subtitle">
            {{item.totalPlays > 0 ? item.totalPlays + ' Times played' : ''}}
        </mat-card-subtitle>
        <mat-card-subtitle class="item-subtitle">
            {{item.totalDownloads > 0 ? item.totalDownloads + ' Times downloaded' : ''}}
        </mat-card-subtitle>

        <!-- <button class="btn btn-primary" (click)="onPlayOrPause()">
            {{ isPlaying ? 'Pause' : 'Play' }}
        </button> -->
       
        <track-control [audioUrl]="item.audio"></track-control>
    </mat-card-content>
</mat-card>