<mat-toolbar role="heading" class="mat-elevation-z3">

    <div class="col-md-3 col-xs-1" routerLink="/khatma">

        <span>
            <!-- <i class="fa fa-compact-disc"></i> -->
            <img src="../../../assets/images/app_launcher.png" alt="" style="width: 36px; height: 36px;">
            &nbsp;
            <span class="header-text"> Kawtharuna</span>
        </span>
    </div>

    <div class="col-md-6 col-xs-11" *ngIf="withSearch">

        <div class="search-container">
            <i class="fa fa-search search-icon"></i>

            <input type="text" placeholder="Search by title" class="search-input" #searchBox>

            <mat-list class="mat-elevation-z3 search-result" *ngIf="searchResults.length>0 && !hideResult">

                <mat-list-item class="search-result-item" role="listitem" *ngFor="let artist of searchResults">

                    <a (click)="onResultClick()" [routerLink]="[artist.artistId, artist.artistName]">
                        {{artist.artistName }}</a>
                </mat-list-item>

            </mat-list>

        </div>

    </div>


    <div *ngIf="isLoggedIn">
        <button mat-flat-button color="primary" (click)="onLogout()">
            <span class="header-text"> Logout </span>
        </button>
    </div>
    <div *ngIf="!isLoggedIn">
        <button mat-flat-button color="primary" (click)="onLogin()">
            <span class="header-text"> Login </span>
        </button>
    </div>


</mat-toolbar>