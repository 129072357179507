<mat-sidenav-container class="sidenav-container" autosize>

    <mat-sidenav #sidenav fixedInViewport="true" mode="side" opened="{{ true }}">
        <side-nav (toggleMenu)="toggleMenu()" [isExpanded]="isExpanded"></side-nav>
    </mat-sidenav>


    <mat-sidenav-content class="sidenav-content" >
        <div class="content-container">

            <router-outlet></router-outlet>

        </div>

        <mat-toolbar role="footer" class="mat-elevation-z3 music-control">
            <app-player></app-player>
        </mat-toolbar>
    </mat-sidenav-content>
</mat-sidenav-container>


<!-- <div class="body_container">
    <router-outlet></router-outlet>
</div>

<mat-toolbar role="footer" class="mat-elevation-z3 music-control">
    <app-player></app-player>
</mat-toolbar> -->